import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';
const Article = () => {
    const [articles,setArticles]=useState([]);
  useEffect(()=>
  {
    const fetchArticle = async () => {
        try {
          const response = await axios.get("/post");
          setArticles(response.data);
          // console.log(response.data);
        } catch (err) {
          console.error(err);
        }
      };
      fetchArticle();
  },[]);
  return (
    <>
    <h1>Articles</h1>
    <ul>
        {articles.map((article) =>
        (
            <li key={article._id}>
         <Link to={`/post/${article._id}`}>
         
          <div className="card mb-3">
  <div className="row g-0">
    <div className="col-md-4">
      <img  src={article.imageUrl} className="img-fluid rounded-start" alt="..."/>
    </div>
    <div className="col-md-8">
      <div className="card-body">
        <h5 className="card-title">{article.title}</h5>
        <p className="card-text">{article.description.substring(0,100)}...</p>
      </div>
    </div>
  </div>
</div>
</Link> 
          </li>
          
        )
        )}
      </ul>
    </>
  )
}

export default Article
