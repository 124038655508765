import React from 'react'

const About = () => {
  return (
    <>
      <h1>About Page</h1>
        <p>
        Thank you for visiting. We hope you find our blog a valuable resource and an enjoyable journey through the power of words. Together, let's embark on a quest for knowledge, inspiration, and meaningful conversations.
        </p>
       
    </>
  )
}

export default About
