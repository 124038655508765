import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import Home from './pages/Home';
import Article from './pages/Article';
import Auth from './pages/Auth';
import About from './pages/About';
import Post from './pages/Post';
function App() {
  return (
    <>
    <Router>
    <Navbar/>
    <div id='page-body'>
      <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/post" element={<Article/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path="/Auth" element={<Auth/>}/>
      <Route path="/post/:articleide" element={<Post/>}/>
      </Routes>
      </div>
    </Router>
    </>
  );
}

export default App;
