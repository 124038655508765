import React from 'react'

const CommentsList = ({comments}) => {
  return (
   <>
    <h3>Comments:</h3>
    {comments.map(comment=>
    (
        <div className='comment' key={comment._id}>
            <h4>{comment.author}</h4>
            <p>{comment.text}</p>
        </div>
    ))}
   </>
  )
}

export default CommentsList