import React, { useState } from 'react'
import { useGetUserName } from '../hooks/getUserName';
import axios from 'axios';
const AddComments = ({PostId,updatePost}) => {
    const [comment,setComment]=useState('');
    const userName=useGetUserName();
    // console.log(PostId);
    const addComment=async()=>
    {
        
          const response=await axios.post(`/post/${PostId}/comments`,
          {author:userName,text:comment})
          const updatedPost=response.data;
          updatePost(updatedPost);
          setComment('');
    }
  return (
    <div id="add-comment-form">
      <h3>Add a Comment</h3>
     <br/>
      <textarea rows='4' cols='50' value={comment} onChange={(e)=>setComment(e.target.value)}/>
      <button onClick={addComment}>Add Comment</button>
    </div>
  )
}

export default AddComments
