import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import AddComments from '../components/AddComments';
import axios from 'axios';
import CommentsList from '../components/CommentList';
import { useGetUserID } from '../hooks/getUserId';
const Post = () => {
    const [post,setPost]=useState({title:'',imageUrl:'',comments:[],description:'',upvotes:0});
    const {articleide}=useParams();
    const [canUpvote,setCanUpvote]=useState(true);
    const user=useGetUserID();
    useEffect(()=>
    {
        const fetchPost=async()=>
        {
            const response= await axios.get(`/post/${articleide}`);
            const willUpvote=response.data.upvotesIds;
            if(willUpvote.includes(user))
            {
              setCanUpvote(false);
            }
            setPost(response.data);
        }
        fetchPost();
    },[]);
    const fetchPost=async()=>
        {
            const response= await axios.get(`/post/${articleide}`);
            const willUpvote=response.data.upvotesIds;
            if(willUpvote.includes(user))
            {
              setCanUpvote(false);
            }
            setPost(response.data);
        }
    // console.log(canUpvote);
    // console.log(user);
     const addUpvote=async()=>
     {           
                //  console.log(uid);
              if(canUpvote)
              {
              const response=await axios.put(`/post/${articleide}/upvote`,{
                uid:user
              });
              setPost(response.data);
              fetchPost();
            }
            else{
                alert("You can Only Upvote a article Once");
            }
     }
  return (
    <>
       
       <div>
  <h1>{post.title}</h1>
  <div className='card-img-top'>

  <img className='card-img'  src={post.imageUrl} alt={post.title}/>

  </div>
  <div className='upvote-section'>
         
        {user? <button onClick={addUpvote}>{canUpvote?'Upvote':'Thanks for Upvote'}</button> :<Link to='/Auth'><button>Login to Upvote</button></Link>}
        <p>This article has {post.upvotes} upvote(s)</p>
      </div>
  <p>{post.description}</p>
</div>
 
 {user?<AddComments PostId={articleide} updatePost={updatedPost=>setPost(updatedPost)}/>:<Link to='/Auth'><button>Login to comment</button></Link>}
  <CommentsList comments={post.comments}/>
    </>
  )
}

export default Post
