import React from 'react'
import { useGetUserName } from '../hooks/getUserName'
const Home = () => {
  const userName=useGetUserName();
  return (
    <>
    <h1>Hello {userName}, welcome to my blog!</h1>
    <p>
    Welcome to my blog, your go-to destination for insightful articles, thought-provoking discussions, and a wide range of topics that cater to your diverse interests. Whether you're a passionate reader, a knowledge seeker, or simply looking for inspiration, our blog is here to inform, engage, and entertain you.
    </p>
    
</>
  )
}

export default Home
