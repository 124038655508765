import React from 'react'
import {Link} from 'react-router-dom'
import { useGetUserID } from '../hooks/getUserId'
import { useNavigate } from 'react-router-dom'
const Navbar = () => {
    const user=useGetUserID();
    const navigate=useNavigate();
    const logOut=()=>
    {
        window.localStorage.removeItem("userID");
        navigate("/auth");
    }
  return (
    <>
        <header className="p-3 text-bg-dark">
    <div className="container">
      <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        

        <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
          <li><Link to="/" className="nav-link px-2 text-primary">Home</Link></li>
          <li><Link to="/about" className="nav-link px-2 text-white">About</Link></li>
          <li><Link to="/post" className="nav-link px-2 text-white">Articles</Link></li>
        </ul>
        <div className="text-end">
          {user?<button className="btn btn-info" onClick={logOut}>logOut</button>:<Link to='/auth'><button className="btn btn-danger">LogIn</button></Link>}
        </div>
      </div>
    </div>
  </header>
    </>
  )
}

export default Navbar
