import React, { useState } from 'react'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
const Auth = () => {
  return (
    <div className='auth'>
      <Login/>
      <Register/>

    </div>
  )
}
const Login=()=>
{   const[username,setUsername]=useState("")
const[password,setPassword]=useState("");
    const navigate=useNavigate();
const onSubmit=async(e)=>
{
      e.preventDefault();
      try{
        const response=await axios.post("/auth/login",{username,password})
        if(response.data.userID)
        {
        window.localStorage.setItem("userID",response.data.userID);
        
        navigate("/");
        }
        if(response.data.userName)
        {
            
        window.localStorage.setItem("userName",response.data.userName);
        }
        else{
            alert("Invalid username or password");
        }
      }
      catch(error)
      {
        console.error(error);
      }
}
    return(
        <Form username={username} setUsername={setUsername} password={password} setPassword={setPassword} onSubmit={onSubmit} label="Login"/>
       )
}
const Register=()=>
{ 
    const[username,setUsername]=useState("")
    const[password,setPassword]=useState("");
     const onSubmit=async(e)=>
     {
        e.preventDefault();
        try{
            await axios.post("/auth/register",{username,password});
            
              alert("User has been regsitered");
            
        }
        catch(err){
                console.error(err);
        }
     }   
     return(
    <Form username={username} setUsername={setUsername} password={password} setPassword={setPassword} onSubmit={onSubmit} label="Register"/>
   )
}
const Form=({username,setUsername,password,setPassword,label,onSubmit})=>
{
    return(
        <div className='auth-container'>
        <h2>{label}</h2>
        <form onSubmit={onSubmit}>
       <div className='form-group'>
         <label htmlFor='username'>Username</label>
         <input type="text" id='username' value={username} onChange={(e)=>setUsername(e.target.value)}/>
         </div>
         <div className='form-group'>
         <label htmlFor='password'>Password</label>
         <input type="password" id='password' value={password} onChange={(e)=>setPassword(e.target.value)}/>
         </div>
         <button  className='form-btn' type='submit'>{label}</button>
        </form>
    </div>

    )
}
export default Auth

